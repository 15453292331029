.descValue {
    margin: 0px;
    margin-top: 3px;
    padding-right: 2px;
    font-weight: 400;  
    display: -webkit-box;
    font-size: 14px;
    -webkit-line-clamp:1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
.descValue:hover {
    display: block;
    -webkit-line-clamp:auto; 
    overflow: auto; 
    white-space: normal;
    height:auto; 
}
.mystyle {
    margin-top: 2px;
    font-size: small;
    color: #999;
}
.personalcard {
  margin-bottom: 15px;
  display: grid;
  grid-template-columns: auto auto  ;
  justify-content: start;
}
.valuesize {
  font-size: smaller;
}
.rows {
  display: flex;
  flex: 1;
  margin-bottom: 15px;
}
.row1 {
  flex: 1;
}
.row2 {
  flex: 1;
}
.title {
  display: flex;
  justify-content: center;
  align-items: center;
}
.labelTitle {
  text-align: center;
  font-size: large;
  font-weight: 600;
}
@media (min-width: 200px) and (max-width: 900px){
  .labelTitle {
     text-align: center;
     font-size: x-large;
     font-weight: 700;
  }
}
.divItem {
  padding-top: 5px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  color: currentColor;
}
.labelName {
  flex: 1;
  font-size: 14px;
  font-weight: 400;
  color: gray;
}
.labelValue {
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: currentColor;
}
@media (min-width: 200px) and (max-width: 900px){
  .divItem {
    padding-left: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    color: currentColor;
  }
}
@media (min-width: 200px) and (max-width: 900px){
  .labelName {
    flex: 1;
    font-size: 18px;
    font-weight: 400;
    color: gray;
  }
}
@media (min-width: 200px) and (max-width: 900px){
  .labelValue {
    margin-top: 10px;
    margin-bottom: 10px;
    font-Size: 20px;
    font-weight: bold;
    color: currentColor;
  }
}